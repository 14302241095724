input, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: white;
    padding: 14px 16px;
    border-radius: 6px;
    border: 0px solid black;
    color: rgba(0,0,0,0.8);
    font-size: 1em;
    font-family: sans-serif;
    font-weight: 300;
    max-width: 100%;
    width: 100%;
    resize: none;
    display: block;

    &::placeholder {
        opacity: 0.5;
    }

    &:focus {
        outline: none;
        box-shadow:0 0 0 3px $tint-color;
    }
}

textarea {
    min-height: 147px;
    line-height: 1.5;
    -webkit-overflow-scrolling: touch;
}

.input-group {
    margin-top: 16px;
}

button, .btn {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    padding: 10px 15px;
    min-height: 30px;
    min-width: 30px;
    color: #eee;
    font-family: 'Texta-Black', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0,0,0,0.17);
    line-height: 1.4;

    transition: all 0.3s;

    background-image: linear-gradient(180deg, rgba(255,255,255,0.24) 0%, rgba(0,0,0,0.24) 100%);
    box-shadow: 0 3px 4px 1px rgba(0,0,0,0.13), inset 0 0 0 1px rgba(255,242,242,0.14);
    border-radius: 25px;

    &:hover {
        text-decoration: none;
        background-color: $tint-color;
        cursor: pointer;
    }

    &:active {
        background-image: linear-gradient(180deg, $tint-bottom, darken($tint-top, 20%));
        box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.6), 0 1px 0 0 rgba(255,255,255,0.5);
        transform: translateY(0px);
    }
  }

.icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    min-width: 40px;

    &:hover {
        background-color: transparent !important;
    }

    &--dribbble {
        background-image:url("../images/social/dribbble.svg");
    }

    &--linked-in {
        background-image:url("../images/social/linked_in.svg");
    }

    &--github {
        background-image:url("../images/social/github.svg");
    }

    &--contact {
        background-image:url("../images/social/contact.svg");
    }
}

.theme-dark .icon {
    &--dribbble {
        background-image:url("../images/social/dribbble_white.svg");
    }

    &--linked-in {
        background-image:url("../images/social/linked_in_white.svg");
    }

    &--github {
        background-image:url("../images/social/github_white.svg");
    }

    &--contact {
        background-image:url("../images/social/contact_white.svg");
    }
}

.btn-secondary {
    position: relative;
    display: inline-block;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: inherit;
    border-radius: 18px;
    transition: 0.3s;
    border: 2px solid rgba(0,0,0,0.3);
    height: 36px;
    padding: 0 15px;
    line-height: 30px;

    &:hover {
        text-decoration: none;
        background-color: rgba(0,0,0,0.3);
    }

    &.active::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        left: 50%;
        top: 95%;
        transform: translateX(-50%);
        box-shadow: 0 1px 1px rgba(0,0,0,0.2);
    }

    &:hover, &.active {
        border-color: transparent;
        background-size: 24px;
    }
}

label {
    display: block;
    font-family: 'TextaAlt-Medium';
    text-transform: uppercase;
    font-size: 0.9em;
    margin-bottom: 4px;
}

*:hover > span.tooltip {
    display: block;
}

span.tooltip {
    display: none;
    position: absolute;
    z-index: 100;
    background: #333;
    color: #eee;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 14px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    left: 50%;
    top: calc(100% + 4px);
    white-space: nowrap;
    text-transform: uppercase;
    transform: translateX(-50%);

    &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: 50%;
        width: 0; 
        height: 0; 
        border-right: 6px solid transparent;
        border-left: 6px solid transparent; 
        border-bottom: 6px solid #333; 
        transform: translateX(-50%);
    }
}

.theme-dark span.tooltip {
    color: black;
    background-color: #eee;

    &::before { 
        border-bottom-color: #eee;
    }
}

select {
    display: block;
    width: 100%;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px 12px;
    padding-right: 42px;
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15), inset 0 1px 0 0 rgba(255,255,255,0.14);
    border: none;
    border-radius: 6px;
    font-size: 18px;
    text-shadow: 0 1px rgba(0,0,0,0.2);
    background-image:
        url('../images/select-arrows.svg'),
        linear-gradient(180deg, $tint-top, $tint-bottom),
        linear-gradient(180deg, #B0B0B0 0%, #727272 100%);
    background-size: 10px 18px, 32px 100%, 100%;
    background-repeat: no-repeat;
    background-position: calc(100% - 11px) 12px, right, center;

    option {
        text-shadow: none;
        color: black;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), 0 0 0 3px $tint-color;
    }
}

.theme-dark {
    .btn-secondary {
        border-color: rgba(255, 255, 255, 0.3);
    }

    input, textarea {
        background: rgba(255,255,255,0.4);
        box-shadow: 0 1px 0 #eee, inset 0 1px 2px rgba(0,0,0,0.2);
    }

    *:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.9), 0 0 0 3px $tint-color;
    }

    select {
        color: #666;
        text-shadow: none;
        background-image:
        url('../images/select-arrows.svg'),
        linear-gradient(180deg, #706BFC 0%, #4B46CE 100%),
        linear-gradient(180deg, rgb(230, 230, 230) 0%, #b9b9b9 100%);
    }
}