.site__header {
    color: inherit;
    padding: 30px 0;
    width: 100%;

    #skip-to-content {
        position: absolute;
        text-indent: -1000px;

        &:focus {
            position: relative;
        }
    }

    input[type=checkbox]{
        display: none;
    }

    a[title=home]{
        position: relative;
    }
    
    .text {
        text-align: center;

        h1 {
            font-family: 'TextaAlt-Medium';
            font-size: 22px;
        }

        h2 {
            font-family: 'TextaAlt-Regular';
            font-size: 14px;
            opacity: 0.6;
            white-space: nowrap;
        }
    }

}

@media (min-width: $breakpoint-md) {
    .site__header {

        .mobile-toggle {
            display: none;
        }

        .text {
            text-align: left;
        }
    }
}