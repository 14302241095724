
.card--obsidian {
    background: gray;
    background-image: linear-gradient(134deg, #4A4A4A 0%, #141414 100%);

    .screenshot-obsidian {
        background-image: url("/assets/images/device/iPhoneX.png"), url("/assets/obsidian/iPhoneShot.jpg");
        background-size: contain, 192px 414px, 230px 460px;
        width: 224px;
        height: 445px;
        margin: 0 auto;
        transform: scale(1.15);
    }

    .content {
        z-index: 2;
        padding: 30px 0;
    }

    .pictographs {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;

        > * {
            width: 100%;
            height: 100%;
            background-size: contain;
            position: absolute;
        }

        > .p1 {
            background-image: url("/assets/obsidian/p1.svg");
        }

        > .p2 {
            background-image: url("/assets/obsidian/p2.svg");
        }

        > .p3 {
            background-image: url("/assets/obsidian/p3.svg"); 
        }
    }
}