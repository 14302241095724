
#page_piano_play {
    //background-color: rgb(10, 10, 39);
    background-color: rgb(49, 31, 110);
    background-image: radial-gradient(circle at 50% 0%, rgba(241,88,153,0.37) 0%, rgba(241,88,153,0) 50%), linear-gradient(117deg, #0C0A45 0%, #001a27 100%);

    section {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 20%;

        .figure-wrap {
            position: relative;
            width: 100%;

            figure {
                position: absolute;
                top: 0px;
                width: 100%;
                height: 100%;
                mask-repeat: no-repeat;
                mask-size: contain;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

    .info {
        position: relative;
        max-width: 460px;
        z-index: 2;

        .title {
            font-size: 2em;
        }

        .tagline {
            font-size: 1.2em;
            opacity: 0.6;
            padding: 20px 0;
            line-height: 1.2;
        }
    }

    #bottom-cta {
        text-align: center;
        padding: 3%;

        .info {
            min-width: 100%;
            display: flex;
            flex-direction: column;

            .title {
                margin-bottom: 12px;
            }
        }
    }

    #hero {
        flex-direction: column-reverse;

        .figure-wrap {
            padding-bottom: 56%;
            mask-image: linear-gradient(black 70%, transparent);

            figure {
                background-image: url("/assets/pianoplay/Hero@2x.jpg");
                mask-image: url("/assets/pianoplay/Hero_Mask.png");
            }
        }

        .info {
            text-align: center;
            max-width: 540px;
            margin: -8% auto 0;

            .tagline {
                font-size: 2em;
            }
        }
    }

    #shareplay {

        .figure-wrap {
            padding-bottom: 68%;

            figure {
                background-image: url("/assets/pianoplay/iPad_SharePlay@2x.jpg");
                mask-image: url("/assets/pianoplay/iPad_SharePlay_Mask.png");
            }
        }

        .info {
            text-align: left;
            max-width: 340px;
        }
    }

    #icloud {
        .figure-wrap {
            padding-bottom: 80%;
            mask-image: linear-gradient(black 88%, transparent);

            figure {
                background-image: url("/assets/pianoplay/iPad_iCloud@2x.jpg");
                mask-image: url("/assets/pianoplay/iPad_iCloud_Mask.png");
            }
        }

        .info {
            text-align: left;
            width: 320px;
        }
    }

    #dual {
        .figure-wrap {
            padding-bottom: 81%;
            //mask-image: linear-gradient(90deg, transparent 0%, black 35%);

            figure {
                background-image: url("/assets/pianoplay/iPad_Dual@2x.jpg");
                mask-image: url("/assets/pianoplay/iPad_Dual_Mask.png");
            }
        }

        .info {
            text-align: left;
            max-width: 320px;
        }
    }
}


.piano-card {
    background-color: rgb(10, 10, 39);
    background-image: radial-gradient(circle at 50% 0%, rgba(241,88,153,0.37) 0%, rgba(241,88,153,0) 100%), linear-gradient(117deg, #0C0A45 0%, #001a27 100%);
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    .info {
        text-align: center;
    }
    
    .segment {
        width: 100%;
    }

    figure {
        width: 300px;
        margin: 0 auto;
        
        img {
            width: 100%;
            height: auto;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-image: url("/assets/pianoplay/iPhone_SharePlay_Mask.png");
        }
    }
}

@media (min-width: $breakpoint-lg) {
    #page_piano_play {
        .info {
            margin-bottom: -19%;

            .title {
                font-size: 3em;
            }

            .tagline {
                font-size: 1.3em;
            }
        }

        #dual .info {
            margin-bottom: 0;
        }

        #icloud .info {
            margin-bottom: -30%;
        }
    }

    .piano-card {
        flex-direction: row;
        justify-content: space-between;

        .segment {
            width: 50%;
        }
    }
}