$gutter: 4%;
$padding: 16px;

.wrapper {
    > *:not(.exclude) {
        position: relative;
        max-width: 960px;
        width: calc(100% - env(safe-area-inset-right) - env(safe-area-inset-left) - 8%);
        // margin auto assumes device has equal safe-area-insets on opposite sides which may not be the case
        // to be safe using explicit inset for it's margin side ( :( does not work with max-width )
        //margin-right: calc(env(safe-area-inset-right) + 3%);
        //margin-left: calc(env(safe-area-inset-left) + 3%);
        margin-left: auto;
        margin-right: auto;
    }

    &--sm > *:not(.exclude) {
        max-width: 780px;
    }

    &--lg > *:not(.exclude) {
        max-width: 1200px;
    }
}

.no-wrap {
    white-space: nowrap;
}

.spacer {
    width: 100%;
    height: 100%;
}

.h-stack {
    display: flex;
    align-items: center;
    flex-direction: row;

    &--pad {
        > *:not(:last-child) {
            margin-right: $padding;
        }
    }

    &--pad-lg {
        > *:not(:last-child) {
            margin-right: $padding * 2;
        }
    }

    &--pad-sm {
        > *:not(:last-child) {
            margin-right: $padding / 2;
        }
    }

    &--alg-top {
        align-items: top;
    }

    &--alg-center {
        align-items: center;
    }

    &--alg-bottom {
        align-items: bottom;
    }
}

.v-stack {
    display: flex;
    align-items: left;
    flex-direction: column;

    &--pad {
        > *:not(:last-child) {
            margin-bottom: $padding;
        }
    }

    &--alg-leading {
        align-items: left;
    }

    &--alg-center {
        align-items: center;
    }

    &--alg-trailing {
        align-items: right;
    }
}

.flex {
    display: flex;
    justify-content: space-between;

    &--collapse {
        flex-direction: column;
    }
}

.align {
    &-bottom { align-items: flex-end; }
    &-center { align-items: center; }
}

.col {
    width: 100% !important;
    &:first-child {
        //margin-bottom: 30px;
    }
}


@media (max-width: $breakpoint-md) {
    .h-stack {
        &--collapse { 
            flex-direction: column;

            .spacer { display: none; }

            &.h-stack--pad {
                > *:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $padding;
                }
            }

            &.h-stack--pad-lg {
                > *:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $padding * 2;
                }
            }

            &.h-stack--pad-sm {
                > *:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $padding / 2;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .flex {
        flex-direction: row;

        &.direction-rev {
            flex-direction: row-reverse;
        }

        > .col {
            @for $i from 1 through 11 {
                &-#{$i} { width: calc((100% / 12) * #{$i}); }
            }
            &-12 { width: 100%; }
        }
    }

    
    .flex-gutter > .col {
        @for $i from 1 through 11 {
            $col: 12 / $i;
            $g: ($col - 1) * $gutter;  
            &-#{$i} { width: calc(((100% - #{$g}) / 12) * #{$i}); }
        }
        &-12 { width: 100%; }
    }
}