.card {
    //background-color: #888;
    transition: all 0.3s;
    overflow: hidden;
    transform: translateZ(0);
    //background-image: linear-gradient(180deg, rgb(229, 234, 238) 0%, #bac1c5 100%);
    //box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.034), 0 0 0 1px rgba(0,0,0,0.2);
    border-radius: 20px;
    padding: 20px;

    input[type=checkbox] {
        display: none;
  
        &:checked ~ .card__content {
            max-height: 3000px;
            padding: 5%;
        }
    }
  
    &__content { 
        overflow: hidden;
        transition: all 0.3s;
        padding: 20px;
    }
  
    &__footer {
        background: rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-top: 1px solid black;
    
        > * {
            margin: 0;
        }
    }
}

.card {
    &--info {
        background: rgba(0,0,0,0.5);
        -webkit-backdrop-filter: blur(16px);
        //max-width: 340px;
        overflow: scroll;
        color: #fff;
        text-align: center;
        border-radius: 16px;
        box-shadow: 0 15px 30px 2px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.5);

        img {
            border-radius: 12px;
            margin-bottom: 16px;
        }

        .actions {
            margin-top: 16px;
        }
    }

    &--404 {
        margin: 20% auto;
        text-align: center;

        p {
            margin-top: 20px;
            opacity: 0.6;
        }
    }
}

.card--proj {
    transition: all 0.5s;
    transform: translateZ(0px);
    padding: 0px;

    &.interactive:hover {
        .card__overlay > * {
            opacity: 1;
            transform: scale(1) translateY(0px);
        }
    }

    .card__overlay {
        position: absolute;
        height: 100%;
        padding: 0px;
        z-index: 4;
        transition: all 0.3s;
        width: 100%;
        right: 0;
        overflow: scroll;
        opacity: 0; 
        //background-color: blue;

        & > * {
            float: right;
            height: 100%;
            //background-color: red;
            //width: 50%;
            //opacity: 0;
            //transform: scale(1.1) translateY(50px);
        }

        &:hover {
            opacity: 1;
        }
    }

    .card__content {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        padding: 0;

        > * {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: left bottom;
            transform: translateZ(1px); 
        }
    }

    .card__bg {
        z-index: 1;

        img {
            display: inline-block;
            width: 100%;
            height: 10vh;
        }
    }

    .card__link {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        text-indent: -1000px;
    }
}

.card-layout {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    > *:first-child {
        min-width: 300px;
        max-width: 600px;
        width: 100%;
        z-index: 10;
    }

    .content {
        width: 100%;
    }
}


@media (min-width: 760px) {
    .card--proj {
        
        &--header {

            .card__overlay {
                opacity: 1;
            }

            .card--info {
                padding: 60px;
                font-size: 2em;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 21px;
                }
            }
        }

        .card__overlay {
            padding: 20px;

            > * {
                width: 50%;
            }
        }
    }

    .card-layout {
        flex-direction: row-reverse;

        > *:first-child {
            width: 350px;
        }

        .content {
            width: calc(100% - 350px);
        }
    }
}


.testimonial {
    background-color: lighten($tint-color, 34%);
    background: linear-gradient(rgba(0, 132, 255, 0.2), rgba(0, 89, 255, 0.04));
    border: 1px solid rgba(0, 132, 255, 0.2);
    padding: 8px;
    border-radius: 32px;
    max-width: 680px !important;

    .person {
        width: 210px;
        border-radius: 25px;
        align-items: center;
    }

    .profile-image {
        width: 50px;
        height: 50px;
    }

    h2 {
        font-family: 'TextaAlt-Medium', sans-serif;
        font-size: 1.3em;
        line-height: 1;
        margin-top: 8px;
    }

    h3 {
        font-family: 'TextaAlt-Regular', sans-serif;
        font-size: 1em;
        opacity: 0.5;
        margin-bottom: 0;
    }

    .links {
        margin-top: 10px;
        display: none;
    }

    li {
        display: inline;
        margin-bottom: 0;
    }

    p {
        margin: 0;
        line-height: 1.3;
        padding: 20px 20px 0px 30px;
    }
}