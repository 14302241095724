.site__footer {
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 40px;
}

.theme-dark .site__footer {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.7);
}