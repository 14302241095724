#page_cake_3d {
    background-color: #1B2966;
    background-image: radial-gradient(circle at 50% 23%, #1B2966 1%, #07040B 79%);
    color: white;


    #hero {
        display: flex;
        align-items: center;
        min-height: 80vh;
       // background-color: red;

        > div {
            width: 50%;
        }

        .info {
            text-align: center;
        }

        figure {
            width: 400px;
            
            img {
                //position: absolute;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: contain;
                -webkit-mask-image: url("/assets/cake/MacBookMask.png");
            }
        }
    }
}

.cake-card {
    background-color: #1B2966;
    background-image: radial-gradient(circle at 50% 23%, #1B2966 1%, #07040B 79%);
    color: white;
    display: flex;
    align-items: center;

    > div {
        width: 50%;
        padding: 16px;
    }

    .info {
        text-align: center;
    }

    figure {
        width: 300px;
        
        img {
            display: block;
            width: 100%;
            height: auto;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            -webkit-mask-image: url("/assets/cake/MacBookMask.png");
        }
    }
}