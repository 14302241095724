@keyframes rotate {
	0% { transform:rotateZ(0deg); }
	100% { transform:rotateZ(360deg); }
}

@keyframes scale {
	0% { transform:scale(1,1); }
	100% { transform: scale(1.2, 1.2); }
}

@keyframes scaleX {
	0% { transform:scaleX(1); }
	100% { transform: scaleX(1.1); }
}

@keyframes scaleY {
	0% { transform:scaleY(1); }
	100% { transform: scaleY(1.1); }
}

@keyframes translateY {
	0% { transform:translateY(0px); }
	100% { transform: translateY(33px); }
}

@keyframes translateX {
	0% { transform:translateX(0px); }
	100% { transform: translateX(13px); }
}

.anim-scaleX { animation: scaleX 4s alternate infinite; }
.anim-scaleY { animation: scaleY 2.8s alternate infinite; }
.anim-rotate { animation: rotate linear 13.4s infinite; }
.anim-translateY { animation: translateY linear 5s alternate infinite; }
.anim-translateX { animation: translateX linear 3.4s alternate infinite; }