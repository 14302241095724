.element-cutoff {
  position: relative;
  overflow: hidden;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
  margin: 0;
  text-align: center;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-image: linear-gradient(180deg, rgba(40, 44, 52, 0) 0%, rgba(40, 44, 52, 0.7) 100%);
    height: 30px;
    pointer-events: none;
  }
}

@keyframes SlideUp {
  from { transform: translateY(100%); }
  to { transform: translateY(0%); }
}

.device {
  $screenWidth: 220px;
  position: relative;
  z-index: 1;

  &__screen {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    border-radius: 3px;
    background-color: #222;

    > * {
      display: block;
      margin: 0;
      width: 100%;
    }

    &::-webkit-scrollbar { 
      display: none; 
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
    }
  }
  
  &--iPhone8 {
    $screenAspect: 16px / 9px;
    padding: 66px 19px;
    
    .device__screen {
      width: $screenWidth;
      //height: 220px * $screenAspect ;
      height: $screenWidth * $screenAspect;

      &::before {
        width: 257px;
        height: 523px;
        background-image: url("/assets/images/device/iPhone8.png");
      }
    }
  }

  &--iPhoneX {
    $screenAspect: 19.5px / 9px;
    padding: 20px 17px;

    .device__screen {
      width: $screenWidth;
      height: $screenWidth * $screenAspect;

      &::before {
        width: 257px;
        height: 523px;
        background-image: url("/assets/images/device/iPhoneX.png");
        background-size: contain;
      }
    }
  }

  &--iPhone15 {
    padding: 20px 20px;

    .device__screen {
      width: 211px;
      height: 211px * (852/394);
      border-radius: 30px;

      > * {
        border-radius: 20px;
      }

      &::before {
        width: 253px;
        height: 253px * (932/473);
        background-image: url("/assets/Device/iPhone/15.png");
        background-size: contain;
      }
    }
  }

  &--iPad {
    padding: 26px;
    
    .device__screen {
      width: 430px;
      height: 430px * (821/1180);

      &::before {
        width: 480px;
        height: 480px * (960/1320);
        background-image: url("/assets/Device/iPad/Air.png");
        background-size: contain;
      }
    }
  }

  &--MacBook {
    padding: 20px 51px 30px 51px;

    .device__screen {
      width: 382px;
      height: 382px * (832/1280);

      > * {
        display: block;
        transform: translateY(-9px);
      }

      &::before {
        width: 480px;
        height: 480px * (1010/1610);
        background-image: url("/assets/Device/Mac/Air_13.png");
        background-size: contain;
      }
    }
  }

  &--VisionPro {
    width: auto;

    .device__screen {
      border-radius: 0;
      background-color: transparent;

      > * {
        height: 100%;
        width: 100%;
        mask-repeat: no-repeat; 
        mask-size: contain;
        mask-image: url("/assets/Device/Vision/Pro.png");
      }
    }
  }
}


.dev-iPad {
  position: relative;
  display: block;
  padding: 45px;
  
  &::after {
    position: absolute;
    content: "";
    // right: 0;
    left: 0;
    top:0;
    bottom: 0;
    z-index: 100;
    background-image: url("/assets/images/device/iPad.png");
    background-size: cover;
  }

  > * {
    display: block;
    // margin: 35px;
  }
}