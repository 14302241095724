#page_eli_slade {
    background-color: #eef1fc;

    #hero {
        position: relative;
        min-height: 480px;
        height: 80vh;
        padding: 30px 0;

        .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            height: 100%;
            z-index: 10;

            .profile-image {
                width: 100px;
                height: 100px;
                background-image: url("/assets/images/ProfilePose.jpg");
                background-size: contain;
                transform-origin: top left;
                transition: 0.5s transform cubic-bezier(0.045, 0.225, 0.000, 1.555);

                &.playing {
                    transform: scale(1.3);
                }
            }
            
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            h1 {
                font-size: 16px;
                color: $tint-bottom;
            }

            .title {
                font-size: 2em;
                font-family: 'TextaAlt-Medium', sans-serif;
                opacity: 0.5;
                line-height: 1.6;
            }

            .tagline {
                font-size: 3em;
                line-height: 0.9;
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    #page_eli_slade {
        #hero {
            min-height: 600px;

            .info {
                .title {
                    font-size: 3em;
                }

                .tagline {
                    font-size: 5.1em;
                }
            }
        }
    }
}