
.post-card {
    font-size: 20px;
    position: relative;
    padding: 16px;
    overflow: hidden;
    //background: rgba(20, 20, 190, 0.2);
    //perspective: 100px;

    header {
        padding: 10px 0;
    }

    &__excerpt {
        line-height: 1.4;
        opacity: 0.5;
        //transform-origin: top left;
        //transform: rotate3d(1,0,0, -5deg);
    }
}

.overlay-link {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}