.apps {
    > * {
        margin-bottom: 20px;
        border-radius: 26px;
        overflow: hidden;
    }
}

.app-icon {
    border-radius: 12px;
    width: 60px;
    height: 60px;
}

.app-block {
    .segment {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info {
        max-width: 460px;
        padding: 30px;

        .title {
            font-size: 2.2em;
        }

        .tagline {
            font-family: "TextaAlt-Medium";
            font-size: 1.5em;
            opacity: 0.7;
        }

        .copy {
            opacity: 0.5;
        }

        .cta {
            margin-top: 20px;
        }
    }
}


@media (min-width: $breakpoint-lg) {
    .app-block {
        .info {
            .tagline {
                font-size: 1.7em;
            }
        }
    }
}