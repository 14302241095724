.pager__container {
    position: relative;
    overflow: hidden;
    width: 100%;

    .pages {
        position: relative;
        display: flex;
        overflow: hidden;
        scroll-behavior: smooth;
    }

    .page--spacer {
        position: relative;
        min-width: calc((100% - 300px) / 2);
        min-height: 40px;
        display: block;
    }

    .page {
        display: flex;
        min-width: 300px;
        height:  100%;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.4s;
        opacity: 0.3;
        z-index: 1;

        &.current {
            opacity: 1;
            z-index: 2;
        }
    }

    .controls {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 6px;
        display: flex;
        z-index: 100;
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        -moz-backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);

        input {
            width: 10px;
            height: 10px;
            margin: 2px;
            border-radius: 5px;
            background-color: white;
            opacity: 0.2;
            transition: all 0.3s;
            padding: 0 !important;

            &:hover {
                opacity: 0.5;
            }

            &:checked {
                width: 25px;
                opacity: 0.7;
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .pager .pages {
        display: flex;
        flex-direction: column;
        column-gap: 0px;
        width: 100% !important;
        justify-content: start;
        align-items: start;

        .page {
            display: block;
            display: flex;
            justify-content: center;
            width: 100%;
            min-height: calc(100% - 80px);
        }
    }
}