$tint-color: #2d81ff;
$tint-top: lighten($tint-color, 5%);
$tint-bottom: darken($tint-color, 20%);

$breakpoint-sm: 560px;
$breakpoint-md: 760px;
$breakpoint-lg: 860px;
$breakpoint-xl: 960px;

@keyframes Spazz {
  0% { transform: scale(0.5) rotateZ(-10deg); opacity: 0; }
  100% { transform: scale(1) rotateZ(0deg); opacity: 1; }
}

@import "animation";
@import "inputs";
@import "type";
@import "highlight";
@import "layout";
@import "device";
@import "header";
@import "footer";
@import "card";
@import "markdown";
@import "pagination";
@import "tabs";
@import "platform-selector";

//TODO: remove individual blog post styling
// any functionality needed for blog posts should be generalized or inlined in post
@import "p/kingkron";
@import "p/obsidian";

@import "pages/apps";
@import "pages/blog";
@import "pages/piano";
@import "pages/circadian";
@import "pages/wave";
@import "pages/cake";
@import "pages/home";
@import "pages/404";

@import "post-card";

* {
  padding: 0;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 100;
}

.bottom-links {
  padding: 20px;
  text-align: center;
}

.links {
  li {
    list-style: none;
  }
}

.blog-item {
  padding-bottom: 50px;

  &:not(:first-child){
    padding-top: 50px;
  }

  &:not(:last-child){
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  }

  img { 
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 30px;
  }

  video, img {
    border-radius: 6px;
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .info {
    width: 100%;
  }

  .excerpt { opacity: 0.7; }
}

main {
  position: relative;
  overflow: hidden;
  min-height: 50vh;
}

.site {
  font-family: 'TextaAlt-Regular', -apple-system, BlinkMacSystemFont, sans-serif;
}

.pill {
  display: block;
  margin: 0;
  font-family:'Texta-Black', sans-serif;
  font-size: 12px;
  padding: 4px 10px;
  height: 20px;
  background-color: $tint-color;
  color: white;
  text-transform: uppercase;
  border-radius: 10px;
}

.post-cover {
  position: relative;
  max-height: 500px;
  min-height: 120px;
  border-radius: 0 0 15px 15px;
  overflow: hidden;

  .fade {
    position: absolute;
    top:0;
    left:0;
    right:0;
    height: 150px;
    background-image: linear-gradient(180deg, #07051A 0%, rgba(6,12,53,0.00) 100%);
  }

  img, video {
    display: block;
    width: 100%;
  }

  figure {
    width: 100%;
    object-fit: cover;
    object-position: bottom;

    > * {
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
}

.aspect {
  width: 100%;
  padding-top: 60%;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.scroll-group {
  overflow-x: scroll;
  overflow-y: visible;

  &__container {
    min-width: 100%;
    display: flex;
    justify-content: space-between;

    // & > *:not(:last-child) {
    //   margin-right: 2%;
    // }
  }
}

a {
  color: $tint-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.more {
  font-family:'Texta-Black', sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  padding-right: 30px;
  background-image: url("../images/arrow-right.svg");
  background-repeat: no-repeat;
  background-position: right;
  height: 22px;

  &:hover {
    text-decoration: none;
  }
}

hr {
  position: relative;
  display: block;
  border: none;
  width: 100%;
  height: 30px;

  &::after {
    position: absolute;
    content: "";
    height: 6px;
    width: 100px;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    background: #858eae;
    border-radius: 3px;
  }

}

.profile-image {
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

@media (min-width: $breakpoint-md) {
  .blog-item {
    .cover {
      margin-right: 30px;
    }
  }
}

.theme-light {
  color: black;
  background-color: white;
}

.theme-dark {
  color: white;
  background-color: black;
}

//FIXME: Come back to fix dark mode after redesign

// @media (prefers-color-scheme: dark){
//   body {
//     background-color: #151515;
//     color: #fff;
//   }

//   .btn-secondary {
//     border-color: #666;
//   }

//   .icon {
//     &--dribbble {
//         background-image:url("../images/social/dribbble_white.svg");
//     }

//     &--linked-in {
//         background-image:url("../images/social/linked_in_white.svg");
//     }

//     &--github {
//         background-image:url("../images/social/github_white.svg");
//     }

//     &--contact {
//       background-image:url("../images/social/contact_white.svg");
//     }
//   }
// }