#page_wave_player {
    background-color: #eef1fc;

    .info {
        font-size: 20px;
        text-align: center;
        max-width: 400px;

        .tagline {
            padding: 20px 0; 
            line-height: 1.1;
        }
    }
}


.wave-card-new {
    background: radial-gradient(circle at 50% 0%, #86223D 0%, #2C0606 98%);
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    transition: all 0.5s;

    .tabs[data-tab="0"]{
        .device--iPhone15 {
            display: block;
        }
        .device--iPad {
            display: none;
        }
    }

    .tabs[data-tab="1"]{
        .device--iPhone15 {
            display: none;
        }
        .device--iPad {
            display: block;
        }
    }

    .info {
        text-align: center;
    }

    &[data-page="0"]{
        background: radial-gradient(circle at 50% 0%, #6E3C29 0%, #2C1C0C 100%);
    }

    &[data-page="1"]{
        background: radial-gradient(circle at 50% 15%, #523C69 1%, #221425 90%);
    }

    &[data-page="2"]{
        background: radial-gradient(circle at 50% 0%, #86223D 0%, #2C0606 98%);
    }
}

@media (min-width: $breakpoint-lg) {
    .wave-card-new {
        height: 600px !important;
        flex-direction: row;

        .segment {
            width: 50%;
        }
    }

    #page_wave_player { 
        .wave-card-new {
            height: 600px !important;
        }
    }
}