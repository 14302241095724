// Styling for post content happens inside a markdown tag

.markdown {
    padding-top: 5%;
    padding-bottom: 5%;

    > h1, > h2, > h3, > h4 {
      //margin-top: 60px;
    }

    figure {
      margin-bottom: 30px;
      max-width: 500px;
    
      img, video, svg {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin:  0 auto;
      }
    
      figcaption {
        font-family: 'Texta-BlackIt', sans-serif;
        text-align: center;
        margin: 10px 0;
      }
    }
  
    > ul, > ol {
      margin-bottom: 30px;
      margin-top: 30px;
      font-size: 1.4em;
      line-height: 1.2;
      list-style-position: inside;
      color: #666;
    }
  
    p {
      font-size: 1.35em;
      line-height: 1.3em;
      color: #666;
      margin-bottom: 30px;
    }

    a {
      font-family: "TextaAlt-Medium";
      word-wrap: none;
      white-space: nowrap;
    }

    > h1 {
      line-height: 1;
      margin-bottom: 5px;
    }
  
    > h2 {
      margin-bottom: 10px;
    }
  
    > h3 {
      margin-bottom: 5px;
    }

}