.card--kron {
    position: relative;
    background: #3F3F3F;
    background-image: linear-gradient(180deg, #E9FFE1 0%, #B3DFAD 100%);
    // border: 1px solid rgba(0,0,0,0.25);
    // box-shadow: 0 20px 30px rgba(0,0,0,0.2);
    // height: 600px;
    // margin-top: 100px;
}

.leaf figure {
    width: 200px;
    height: 200px;
    background-image: url("/assets/kingkron/leaf.png");
    background-size: contain;

    animation-name: leafwave;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.leafs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .leaf {
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
    }

    .leaf:nth-child(1){
        left: 5%;
        transform: translate3d(-50%, -30%, 0) rotate(150deg);

        figure {
            animation-duration: 6.5s;
        }
    }

    .leaf:nth-child(2){
        left: 30%;
        transform: translate3d(-50%, -40%, 0) rotate(180deg);

        figure {
            animation-duration: 3.9s;
        }
    }

    .leaf:nth-child(3){
        left: 100%;
        top: 55%;
        transform: translate3d(-50%, -50%, 0) rotate(-90deg);

        figure {
            animation-duration: 3s;
        }
    }

    .leaf:nth-child(4) {
        bottom: -60%;
        left: 60%;

        figure {
            animation-duration: 3.4s;
        }
    }

    .leaf:nth-child(5) {
        top: 90%;
        left: -2%;
        transform: translate3d(-30%, -50%, 0) rotate(45deg);
    }
}

figure.kron-screenshot {
    position: relative;
    left: 50%;
    width: 268px;
    height: 530px;
    transform: translateX(-50%);
}

@keyframes leafwave {
    from{ transform: rotate(0deg); }
    to{ transform: rotate(10deg); }
}

@media (min-width: 760px) {
    figure.kron-screenshot {
        //left: 15%;
    }

    .leafs {
        .leaf:nth-child(2){
            left: 55%;
        }
    }

    .leaf figure {
        width: 350px;
        height: 350px;
    }

    figure.kron-screenshot {
        //transform: scale(1.1);
    }
}