#page_404 {
    background-color: #eef1fc;

    section {
        min-height: calc(100vh - 340px);
        align-items: center;
        display: flex;

        .info {
            text-align: center;
            padding: 20px;
        }
    }
}

@media (min-width: $breakpoint-md) {
    #page_404 section {
        min-height: calc(100vh - 230px);
    }
}