.tabs {
    position: relative;

    &__bar {
        z-index: 100;
        position: absolute;
        margin: auto;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}