
#page_circadian {
    background-color: #eef1fc;

    #hero {
        padding: 40px 0;

        figure {
            position: relative;
            width: 320px;

            > * {
                img {
                    -webkit-mask-repeat: no-repeat; 
                    -webkit-mask-size: contain;
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .phone {
                width: 320px;
                img {
                    -webkit-mask-image: url("/assets/circadian/iPhoneMask.png");
                }
            }
        }

        .info {
            min-width: 360px;
            width: 100%;
            max-width: 700px;

            .app-icon {
                width: 76px;
                height: 76px;
                border-radius: 18px;
            }

            .title {
                font-size: 2.2em;
            }

            .subtitle {
                font-size: 1.2em;
                opacity: 0.6;
            }

            p {
                font-size: 1.25em;
                line-height: 1.2;
                opacity: 0.8;
            }
        }
    }
}


.circadian-card {
    position: relative;
    background-image: url("/assets/circadian/bg.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    text-align: center;
    color: white;

    .segment {
        position: relative;
        width: 50%;
        height: 100%;

        z-index: 1;
    }

    .segment:first-child {
        z-index: 10;
    }

    .tabs {
        width: 100%;
        height: 100%;

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 640px;

            > .device--VisionPro {
                width: auto;
                height: 100%;

                * {
                    display: block;
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-md) {
    #page_circadian  #hero {
        .info {
            min-width: 100%;
        }
    }

    .circadian-card {
        flex-direction: column;

        .segment {
            width: 100%;
        }
    }
}