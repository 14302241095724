.platform-selector {
    display: inline-flex;
    background-color: rgba(0, 0, 0, 0.3);
    -moz-backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 4px;
    border-radius: 26px;
    align-items: center;
    justify-content: center;

    &.vertical {
        flex-direction: column;
    }
    
    &__item {
        display: block;
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        transition: all 0.3s;
        opacity: 0.5;
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0;

        &.selected {
            opacity: 1;
        }

        &.selected::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0px;
            border-radius: 22px;
            background: linear-gradient(180deg, rgba(238,238,238,0.31) 0%, rgba(255,255,255,0.08) 100%);
        }

        &:hover {
           background-size: 20px;
           opacity: 1;
        }

        &.ipad {
            background-image: url("/assets/images/platform_selector/icon/iPad.svg");
        }

        &.iphone {
            background-image: url("/assets/images/platform_selector/icon/iPhone.svg");
        }

        &.macbook {
            background-image: url("/assets/images/platform_selector/icon/MacBook.svg");
        }

        &.vision {
            background-image: url("/assets/images/platform_selector/icon/VisionPro.svg");
        }
    }
}