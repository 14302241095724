#page_blog {
    
    .post-block {
        .title {
            font-size: 2em;
            font-family: 'TextaAlt-Medium', sans-serif;
            opacity: 0.6;
        }

        .subtitle {
            font-size: 2.8em;
            font-family: 'Texta-Black', sans-serif;
            line-height: 1.1;
        }

        .spacer {
            background-color: green;
        }

        a {
            display: inline-block;
            background-color: $tint-color;
            //width: 50px;
            height: 50px;
            color: white;
            line-height: 50px;
            border-radius: 25px;
            padding: 0 50px 0 16px;
            background-image: url("../images/arrow-right.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 16px);
        }
    }

    .featured .post-block {

        .title {
            font-size: 2.8em;
        }

        .subtitle {
            font-size: 4em;
        }

        margin: 60px auto 140px auto;
    }

    .grid {
        .post-block {
            margin-bottom: 16px;
            padding: 16px;
            background-color: rgba(131, 131, 131, 0.2);
            
            border-radius: 12px 12px 38px 12px;
        }
    }
}